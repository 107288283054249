/* Global Css */
body {
  background-color: #f1f1f1 !important;
}

body button.MuiPickersDay-current.MuiPickersDay-daySelected,
body button.MuiPickersDay-daySelected,
body span.MuiTabs-indicator {
  background-color: #e1bb9b;
}
